import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';

import { DATA_TYPE_EVENTS, DATA_TYPE_CONTACTED_PARTICIPANTS } from 'data/config/dataConfig';

import UserDataForm from './UserDataForm';
import ContactedParticipants from './ContactedParticipants';
import Tabs from 'src/components/tabs/Tabs';
import List from 'src/components/list/List';
import config from 'data/config/config';

import Contacts from 'src/pages/user-data/Contacts';

// const LOG_PREF = '[UserDataPageContent] ';

export const PROFILE_TAB_KEY = 'yourProfile';
export const EVENTS_TAB_KEY = 'yourEvents';
export const MET_CONTACTS_TAB_KEY = 'metContacts';
export const CONTACTED_PARTICIPANTS_TAB_KEY = 'contactedParticipants';

class UserDataContent extends Component {
  state = {};

  renderTabContent(tabIndex) {
    switch (tabIndex) {
      case PROFILE_TAB_KEY:
        return (
          <UserDataForm
            status={this.props.formStatus}
            sections={this.props.formSections}
            userData={this.props.userData}
            tosLink={this.props.tosLink}
            externalResourceLinkOpenInInAppBrowser={this.props.externalResourceLinkOpenInInAppBrowser}
            tosLinkOpenInInAppBrowser={this.props.tosLinkOpenInInAppBrowser}
            externalResourceLinkTarget={this.props.externalResourceLinkTarget}
            logout={this.props.logout}
            keyboardHeight={this.props.keyboardHeight}
            labels={this.props.labels}
            actions={this.props.actions}
          />
        );

      case EVENTS_TAB_KEY:
        return (
          <List
            items={this.props.userData.events}
            dataType={DATA_TYPE_EVENTS}
            actions={this.props.actions}
            labels={this.props.labels}
            displayFavorites={false}
            pageKey={USER_DATA_PAGE_KEY}
          />
        );

      case MET_CONTACTS_TAB_KEY:
        return (
          <Contacts
            actions={this.props.actions}
            labels={this.props.labels}
            contacts={this.props.contacts}
            searchResults={this.props.searchResults}
          />
        );

      case CONTACTED_PARTICIPANTS_TAB_KEY:
        return (
          <ContactedParticipants
            actions={this.props.actions}
            labels={this.props.labels}
            participants={this.props.userData.contactedParticipants}
            searchResults={this.props.searchResults}
            keyboardHeight={this.props.keyboardHeight}
          />
        );

      default:
        return null;
    }
  }

  getContactsCount = () => (Array.isArray(this.props.contacts) ? this.props.contacts.length : 0);

  getEventsCount() {
    return Array.isArray(this.props.userData.events) ? this.props.userData.events.length : 0;
  }

  getContactedParticipantsCount() {
    return Array.isArray(this.props.userData.contactedParticipants)
      ? this.props.userData.contactedParticipants.length
      : 0;
  }

  profileTabData = {
    key: PROFILE_TAB_KEY,
    isCurrentTab: () => this.props.tabIndex === PROFILE_TAB_KEY,
    getTabContent: () => this.props.labels.userData.yourProfile,
  };

  eventTabData = {
    key: EVENTS_TAB_KEY,
    isCurrentTab: () => this.props.tabIndex === EVENTS_TAB_KEY,
    isClickable: () => this.getEventsCount() > 0,
    getTabContent: () => this.props.labels.userData.yourEvents,
  };

  metContactsTabData = {
    key: MET_CONTACTS_TAB_KEY,
    isCurrentTab: (key) => this.props.tabIndex === key,
    // isClickable  : () => this.getEventsCount() > 0,
    getTabContent: () => this.props.labels.userData.metContacts,
  };

  contactedParticipantsTabData = {
    key: CONTACTED_PARTICIPANTS_TAB_KEY,
    isCurrentTab: () => this.props.tabIndex === CONTACTED_PARTICIPANTS_TAB_KEY,
    isClickable: () => this.getContactedParticipantsCount() > 0,
    getTabContent: () => this.props.labels.data[DATA_TYPE_CONTACTED_PARTICIPANTS].plural,
  };

  onTabClick = (tabIndex) => {
    this.props.actions.navigate(USER_DATA_PAGE_KEY, {
      tabIndex,
    });
    // this.props.actions.userDataTabIndexUpdate(tabIndex);
  };

  render() {
    let hasContactsFeature = true;
    if (config.CONTACTS.FEATURE_ENABLED !== true || !global.isCordovaContext) {
      hasContactsFeature = false;
    }

    if (Array.isArray(config.CONTACTS.RESTRICTIONS)) {
      let restriction = config.CONTACTS.RESTRICTIONS.find(
        (restr) => restr.pageKey === USER_DATA_PAGE_KEY
      );
      if (restriction) {
        // A restriction has been defined for this page
        if (restriction.for.indexOf(this.props.profile) !== -1) {
          // Restriction applies for this profile, so skip rendering share button
          hasContactsFeature = false;
        }
      }
    }

    let hasEvents = this.getEventsCount() > 0,
      hasContacts = this.getContactsCount() > 0 && hasContactsFeature,
      hasContactedParticipants = this.getContactedParticipantsCount() > 0;

    let tabsData = [this.profileTabData];

    if (hasEvents) {
      tabsData.push(this.eventTabData);
    }
    if (hasContacts) {
      if (
        config.CONTACTS.SAVE_CONTACT_TO_DEVICE.FEATURE_ENABLED === true &&
        global.isCordovaContext
      ) {
        let hasContactsFeature = true;
        if (Array.isArray(config.CONTACTS.SAVE_CONTACT_TO_DEVICE.RESTRICTIONS)) {
          const restriction = config.CONTACTS.SAVE_CONTACT_TO_DEVICE.RESTRICTIONS.find(
            (restr) => restr.pageKey === USER_DATA_PAGE_KEY
          );
          if (restriction) {
            // A restriction has been defined for this page
            if (restriction.for.indexOf(this.props.profile) !== -1) {
              // Restriction applies for this profile, so skip rendering share button
              hasContactsFeature = false;
            }
          }
        }
      }
      if (hasContactsFeature) {
        tabsData.push(this.metContactsTabData);
      }
    }
    if (hasContactedParticipants) {
      tabsData.push(this.contactedParticipantsTabData);
    }

    let multipleTabs = hasEvents || hasContacts || hasContactedParticipants;

    return (
      <div id="ud-content-container" className="content-below-apptoolbar">
        {multipleTabs && (
          <Tabs
            currentIndex={this.props.tabIndex}
            onTabIndexChange={this.onTabClick}
            data={tabsData}
          />
        )}
        {this.renderTabContent(this.props.tabIndex)}
      </div>
    );
  }
}

UserDataContent.propTypes = {
  userData: PropTypes.object,
  tabIndex: PropTypes.string,

  contacts: PropTypes.array,
  contactedParticipants: PropTypes.array,
  searchResults: PropTypes.object,

  formStatus: PropTypes.object,
  formSections: PropTypes.array.isRequired,

  tosLink: PropTypes.string,
  logout: PropTypes.func.isRequired,
  keyboardHeight: PropTypes.number,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  profile: PropTypes.string.isRequired,
};

export default UserDataContent;
