import * as config from 'data/config/config';

import { LOGIN_PAGE_KEY } from 'src/pages/pagesKeys';

import {
  CONFIG_JSON_LOADED,
  HAS_NAVIGATED,
  KEYBOARD_TOGGLED,
  LANG_CHANGED,
  PERFORM_LOGIN,
  PROFILE_CHANGED,
  SET_LOGIN_STATUS,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
  USER_DATA_UPDATED,
} from 'src/store/actionTypes';

import {
  configJsonLoaded,
  getPageCommonDefaultState,
  profileChanged,
  toggleMenu,
  togglePageAfterNavigation,
  updateKeyboardState,
  updateObject,
  updatePageState,
  userDataUpdated,
} from 'src/store/reducers/commons';

import { isSessionValid, logout } from 'src/core/login/LoginService';
import { getAccount } from 'src/core/login/LoginPersistence';
import { getUserData } from 'src/core/user-data/UserDataService';

function getDefaultState() {
  const account = getAccount();

  const commonDefaultState = getPageCommonDefaultState(LOGIN_PAGE_KEY);

  return {
    ...commonDefaultState,
    login: account ? account.username : null,
    password: account ? account.password : null,
    userData: getUserData(),
    isLogging: false,
    isLogged: isSessionValid(),
    logout,
    error: null,
    urls: {
      forgottenPassword: config.LOGIN.forgottenPasswordURL,
      createAccount: config.LOGIN.createAccountURL,
      tos: config.TOU_LINK[commonDefaultState.labels.id],
    },
    urlsTarget: {
      forgottenPassword: config.LOGIN.forgottenPasswordURLTarget,
      createAccount: config.LOGIN.createAccountURLTarget,
      tos: config.TOU_LINK_TARGET,
    },
    urlsOpenInInAppBrowser:{
      forgottenPassword: config.LOGIN.forgottenPasswordOpenInInAppBrowser,
      createAccount: config.LOGIN.createAccountOpenInInAppBrowser,
      tos: config.TOU_LINK_OPENININAPPBROWSER,
    }
  };
}
export const _langChanged = (state, action) =>
  updateObject(state, {
    labels: action.labels,
    tos: config.TOU_LINK[action.labels.id],
  });

function _setLoginStatus(state, action) {
  const newStateValues = {
    isLogging: false,
    isLogged: action.loggedIn,
    userData: action.userData,
    error: action.error,
  };
  if (state.isLogged && !newStateValues.isLogged) {
    // logout case
    newStateValues.password = null;
  }
  return updateObject(state, newStateValues);
}

const _startLogin = (state, action) => updateObject(state, { isLogging: true, error: null });

function _updatePageState(state, action) {
  if (action.pageKey === LOGIN_PAGE_KEY) {
    const updatedState = updatePageState(state, action, LOGIN_PAGE_KEY);

    // Ability to reset `nextRoute` prop
    if (!action.props.nextRoute) {
      updatedState.nextRoute = null;
    }
    // Ability to reset `nextAction` prop
    if (!action.props.nextAction) {
      updatedState.nextAction = null;
    }
    return updatedState;
  }
  return state;
}

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);
    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, LOGIN_PAGE_KEY);
    case KEYBOARD_TOGGLED:
      return updateKeyboardState(state, action);
    case LANG_CHANGED:
      return _langChanged(state, action);
    case PERFORM_LOGIN:
      return _startLogin(state, action);
    case PROFILE_CHANGED:
      return profileChanged(state, action, LOGIN_PAGE_KEY);
    case SET_LOGIN_STATUS:
      return _setLoginStatus(state, action);
    case TOGGLE_MENU:
      return toggleMenu(state, action, LOGIN_PAGE_KEY);
    case UPDATE_PAGE_STATE:
      return _updatePageState(state, action);
    case USER_DATA_UPDATED:
      return userDataUpdated(state, action);

    default:
      return state;
  }
};
