let currentPageKey;

export const getCurrentPageKey = () => currentPageKey;

export function setCurrentPageKey(value) {
  currentPageKey = value;
}

let currentPageProps;

export const getCurrentPageProps = () => currentPageProps;

export function setCurrentPageProps(value) {
  currentPageProps = value;
}
