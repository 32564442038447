module.exports = require('deepmerge')(require('./config.default.js'), {
  native: {
    name: {
      default: 'Siae2019_D',
      fr: 'Siae FR_D'
    },
    id: 'com.mobilespot.siae.dev'
  },
  web: {
    url: 'siae2019-dev.mobile-spot.com' // deploy: {
    //     host: 'kim1',
    //     user: 'www',
    //     path: '/home/www/mobigeo/siae',
    // },

  }
});