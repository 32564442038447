module.exports = {
  name: 'SIAE2019',
  client: 'SIAE',
  description: 'Projet MobiGeo pour l\'application SIAE 2019',
  pluginId: 'mobigeo-plugin-siae2019',
  version: '4.0.6',
  validLangs: ['en', 'fr'],
  native: {
    version: '19',
    author: 'Mobile-Spot',
    backgroundColor: '0xffffff',
    // iOS
    appleTeamId: '2BAP3P29V2',
    iosAppStoreUrl: 'https://itunes.apple.com/app/paris-air-show/id628105892',
    iosBuildTarget: '10.0',
    // Android
    // 'androidapp-id': 'TODO', // unused?
    playStoreUrl: 'https://play.google.com/store/apps/details?id=com.mobilespot.siae',
    androidFadeSplashScreenDuration: 750,
    androidSigning: {
      keyAlias: 'mobile-spot',
      keyStorePassword: 'jee1Uu0Hieloh7bi'
    },
    // Web app to wrap in the cordova app ('ionic/www', 'simple', 'test', 'demo', 'app-react' - see cordova/testapps/)
    app: 'app-react'
  },
  // List the folders of the data bundles to include for this project
  data: {
    default: 'dem7eKah'
  },
  // 'location', 'location.geogroup', 'location.geopush', 'map.route', 'assets.update', 'poi.aroundme', 'favorites'
  features: ['location', 'map.route', 'location.geopush', 'poi.aroundme', 'favorites'],
  location: {
    type: ['nao'],
    // 'nao' | 'intus'
    key: 'AzSF6ZSb18EIhJN6e7AowA@eu',
    files: [{
      '5512.jscx': 'assets/pdbs'
    }, {
      'app.json': 'assets'
    }],
    registering: {
      location: true,
      geofencing: true
    },
    mapping: {},
    forceSynchronize: true
  },
  undeliveredFolders: ['source', 'exports'],
  cordova: true,
  relatedRepos: [{
    src: 'git@bitbucket.org:mobilespotdev/cordova.git',
    postCommands: ['cd cordova && npm install']
  }, {
    src: 'git@bitbucket.org:mobilespotdev/mobigeo.git',
    postCommands: ['cd mobigeo && npm install']
  }, {
    src: 'git@bitbucket.org:mobilespotdev/app-react.git'
  }, {
    src: 'git@bitbucket.org:mobilespotdev/data-siae-2019.git',
    dest: 'app-react/data',
    postCommands: ['cd app-react && yarn', 'cd app-react && npm run set-env dev', 'cd app-react && npm run download-assets', 'cd app-react && npm run download-data-files', 'cd app-react && npm run update-mobigeo']
  }],
  crypto: false,
  cordova_plugins: {
    mobigeo_plugin_dependencies: [{
      id: 'cordova-plugin-file-transfer'
    }, {
      id: 'cordova-plugin-bluetooth-status'
    }],
    app_dependencies: [{
      id: 'cordova-android-support-gradle-release'
    }, // Solve android support dependencies (versions conflict)
    {
      id: 'cordova-plugin-whitelist'
    }, {
      id: 'cordova-plugin-inappbrowser'
    }, {
      id: 'cordova-plugin-splashscreen'
    }, {
      id: 'cordova-plugin-x-socialsharing'
    }, // for warning message: 'config file undefined requested for changes not found ...'
    // see: https://github.com/Pushwoosh/pushwoosh-phonegap-plugin/issues/297#issuecomment-418101837
    // { id: 'pushwoosh-cordova-plugin' },
    {
      id: 'cordova-plugin-urloverride'
    }, {
      id: '../src-app-plugins/cordova-plugin-mobilespot-app',
      variable: 'HOST',
      varValue: 'web.url'
    }, {
      id: 'https://github.com/Mobile-Spot/pushwoosh-phonegap-plugin.git'
    }, {
      id: 'https://github.com/etienneMobileSpot/cordova-plugin-geolocation.git#permissionsTests'
    }, {
      id: 'cordova-plugin-ionic-keyboard'
    }, {
      id: 'cordova-plugin-statusbar'
    }]
  },
  geoGroup: {}
};