import {
  REAL_TIME_CONNECTED,
  SET_LOGIN_STATUS,
  SET_CODE_IDENTIFICATION,
  SYNCHRO_FAVORITES_ICON_CLICKED,
  TOGGLE_FAVORITE,
  SYNC_WITH_FAVORITES_CODE,
} from 'src/store/actionTypes';

import { getToken } from 'src/core/login/LoginPersistence';

import * as SynchronizedFavoritesService from './SynchronizedFavoritesService';
import { FETCH_FAVORITES_CODE } from '../../store/actionTypes';

let isLoggedIn = false;

export default ({ dispatch, getState }) => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case SET_LOGIN_STATUS:
      SynchronizedFavoritesService.setUserId(getToken());

      if (isLoggedIn === true && !action.loggedIn) {
        SynchronizedFavoritesService.onLogout();
      }
      isLoggedIn = action.loggedIn;

      break;

    case SET_CODE_IDENTIFICATION:
      SynchronizedFavoritesService.setCode(action.code, action.fetched);
      break;

    case FETCH_FAVORITES_CODE:
      SynchronizedFavoritesService.getCode();
      break;

    case SYNCHRO_FAVORITES_ICON_CLICKED:
      SynchronizedFavoritesService.handleClickOnIcon();
      break;

    case TOGGLE_FAVORITE:
      // Handle only if action resulted in a favorite update
      if (action.favListUpdated && action.noSync !== true) {
        // Add or remove a favorite
        SynchronizedFavoritesService.set(
          action.id,
          action.dataType,
          SynchronizedFavoritesService.ACTIONS[action.isFav === true ? 'DELETE' : 'CREATE']
        );
      }
      break;

    case SYNC_WITH_FAVORITES_CODE:
      // Auto synchronization on reconnection
      SynchronizedFavoritesService.instantSynchronize();
      break;
    case REAL_TIME_CONNECTED:
      break;

    default:
  }

  return result;
};
