import React from 'react';
import PropTypes from 'prop-types';

import { VALID_DATA_TYPES } from 'data/config/dataConfig';
import { hashCode } from 'src/core/util/StringUtil';

/**
 * Common component to display places
 */
const DetailPlaces = ({
  id,
  originalId,
  entityType,
  places,
  textPlaces,
  labels,
  actions,
  defaultLabel,
}) => {
  if ((Array.isArray(places) !== true || places.length === 0) && !defaultLabel) {
    if (textPlaces)
      return (
        <div className="fiche-related-data">
          <div className="prop-img">
            <div className="prop-left">
              <div className="icon-font">
                <span className="link fa fa-map-marker-alt" />
              </div>
            </div>
            <div className="prop-right location">
              <span>{textPlaces}</span>
            </div>
          </div>
        </div>
      );
    return null;
  }

  const placesPerLabel = {};

  places.forEach((place) => {
    // Skip invalid items
    if (!place) {
      return;
    }

    const label = place.label || labels.common.defaultPlaceLabel;
    let index = label;
    const { info } = place;

    if (info) {
      index += hashCode(info);
    }

    if (!placesPerLabel[index]) {
      placesPerLabel[index] = [];
    }
    placesPerLabel[index].push({
      id: place.id,
      info: place.info,
      label,
    });
  });

  return Object.keys(placesPerLabel).length === 0 ? (
    <div className="fiche-related-data">
      <div className="prop-img">
        <div className="prop-left">
          <div className="icon-font">
            <span className="link fa fa-map-marker-alt" />
          </div>
        </div>
        <div className="prop-right location">
          <span dangerouslySetInnerHTML={{ __html: defaultLabel }}></span> {/** PE2-12 */}
        </div>
      </div>
    </div>
  ) : (
    <div className="fiche-related-data">
      <div className="prop-img">
        <div className="prop-left">
          <div className="icon-font">
            <span className="link">h</span>
          </div>
        </div>
        <div className="prop-right">
          <div>
            <ul className="localisations">
              {Object.keys(placesPerLabel).map((index) => {
                return (
                  <li
                    key={index}
                    className="clickable"
                    onClick={() => {
                      const POI = {
                        id,
                        originalId,
                        type: entityType,
                      };
                      if (placesPerLabel[index].length === 1) {
                        POI.placeId = placesPerLabel[index][0].id;
                      } else {
                        // FIXME:
                        // generate as much POI objects as there are places
                        // let pois = [{id: originalId, type: entityType, placeId: placeId1}, {id: originalId, type: entityType, placeId: placeId2}, ...]
                        // finally call actions.showAllPoisOnMap(pois);
                      }
                      actions.showOnePoiOnMap(POI);
                    }}
                  >
                    <span className="colored-link">{placesPerLabel[index][0].label}</span>
                    {placesPerLabel[index][0].info && (
                      <span className="info">{placesPerLabel[index][0].info}</span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailPlaces.propTypes = {
  originalId: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf(VALID_DATA_TYPES).isRequired,
  places: PropTypes.array,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  defaultLabel: PropTypes.string,
  textPlaces: PropTypes.string,
};

export default DetailPlaces;
