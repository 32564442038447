import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import config from 'data/config/config';

import ChooseLangDialog, {
  CONTAINER_DOM_ID as chooseLangContainerId,
} from 'src/components-standalone/choose-lang-dialog/ChooseLangDialog';
import DataListDialog, {
  CONTAINER_DOM_ID as dataListContainerId,
} from 'src/components-standalone/data-list-dialog/DataListDialog';
import Disclaimer, {
  CONTAINER_DOM_ID as disclaimerContainerId,
} from 'src/components-standalone/disclaimer/Disclaimer';
import FullLoader, {
  CONTAINER_DOM_ID as fullLoaderContainerId,
} from 'src/components-standalone/full-loader/FullLoader';
import Intersticiel, {
  CONTAINER_DOM_ID as intersticielContainerId,
} from 'src/components-standalone/intersticiel/Intersticiel';
import InboxAlert, {
  CONTAINER_DOM_ID as messageAlertContainerId,
} from 'src/components-standalone/inbox-alert/InboxAlert';
import Notifications, {
  CONTAINER_DOM_ID as notificationsContainerId,
} from 'src/components-standalone/notifications/Notifications';
import PollDialog, {
  CONTAINER_DOM_ID as pollDialogContainerId,
} from 'src/components-standalone/poll-dialog/PollDialog';
import SearchPlaceDialog, {
  CONTAINER_DOM_ID as searchPlaceDialogContainerId,
} from 'src/components-standalone/search-place-dialog/SearchPlaceDialog';
import ShareDialog, {
  CONTAINER_DOM_ID as shareDialogContainerId,
} from 'src/components-standalone/share-dialog/ShareDialog';
import NoteModal, {
  CONTAINER_DOM_ID as noteModalContainerId,
} from 'src/components-standalone/note-modal/NoteModal';
import FormModal, {
  CONTAINER_DOM_ID as formModalContainerId,
} from 'src/components-standalone/form-modal/FormModal';

import { INBOX_PAGE_KEY } from 'src/pages/pagesKeys';
import Pages from 'src/pages/Pages';

function mount(component, domId, rootContainerDom, reduxStore) {
  // Mount once
  if (document.querySelector(`#${domId}`)) {
    console.error(
      `Component has already been mounted (or another DOM element already uses id #${domId})`
    );
    return;
  }

  const el = document.createElement('div');
  el.setAttribute('id', domId);
  rootContainerDom.appendChild(el);

  ReactDOM.render(<Provider store={reduxStore}>{component}</Provider>, el);
}

export default (reduxStore, rootEl) => {
  mount(<ChooseLangDialog />, chooseLangContainerId, rootEl, reduxStore);
  mount(<DataListDialog />, dataListContainerId, rootEl, reduxStore);
  mount(<FullLoader />, fullLoaderContainerId, rootEl, reduxStore);
  mount(<Intersticiel />, intersticielContainerId, rootEl, reduxStore);
  mount(<Notifications />, notificationsContainerId, rootEl, reduxStore);
  mount(<SearchPlaceDialog />, searchPlaceDialogContainerId, rootEl, reduxStore);
  mount(<ShareDialog />, shareDialogContainerId, rootEl, reduxStore);
  mount(<NoteModal />, noteModalContainerId, rootEl, reduxStore);
  mount(<FormModal />, formModalContainerId, rootEl, reduxStore);

  if (config.POLL.FEATURE_ENABLED === true) {
    mount(<PollDialog />, pollDialogContainerId, rootEl, reduxStore);
  }
  if (Pages[INBOX_PAGE_KEY]) {
    // Skip component related to inbox if the page is not used in the app
    mount(<InboxAlert />, messageAlertContainerId, rootEl, reduxStore);
  }
  if (global.isCordovaContext !== true) {
    mount(<Disclaimer />, disclaimerContainerId, rootEl, reduxStore);
  }
};
