import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { DATA_TYPE_AIRCRAFTS } from 'data/config/dataConfig';
import { AIRCRAFT_PAGE_KEY /*, FLIGHTS_SCHEDULE_PAGE_KEY*/ } from 'src/pages/pagesKeys';

import Description from 'src/components/fiche/Description';
import DetailPlaces from 'src/components/detail-places/DetailPlaces';
// import DetailEvents       from 'src/components/detail-events/DetailEvents';
import DetailExhibitor from 'src/components/detail-exhibitor/DetailExhibitor';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import ShareButton from 'src/components/share-button/ShareButton';
import NoteButton from 'src/components/notes/NoteButton';
// import { getFlightCounts } from 'src/store/reducers/flightsReducer';

import '../common-fiche.scss';
import './AircraftPage.scss';

class AircraftContent extends PureComponent {
  setScrollableContentEl = (el) => {
    this.contentContainerEl = el;
  };
  scrollTo = (value) => {
    if (this.contentContainerEl) {
      this.contentContainerEl.scrollTop = value;
    }
  };

  onClickOnFavIcon = () => {
    this.props.actions.toggleFavorite(
      this.props.item.id,
      DATA_TYPE_AIRCRAFTS,
      this.props.isFavorite
    );
  };

  render() {
    let lump = this.props.item.lump;
    // hasDescription = !!this.props.item.description;

    const flightCount = 0;
    /*const flightCount = getFlightCounts(
            this.props.item.id,
            this.props.eventsByDays,
            this.props.items
        );*/

    const title = this.props.item.title,
      logoUrl = this.props.item.photo,
      description = this.props.item.description;

    return (
      <div className="fiche content-font content-below-apptoolbar">
        {/* title */}
        <DetailFixedTitle
          title={title}
          isFav={this.props.isFavorite}
          onFavClick={this.onClickOnFavIcon}
          labels={this.props.labels}
        >
          <NoteButton
            itemTitle={this.props.item.title}
            itemId={this.props.item.id}
            dataType={DATA_TYPE_AIRCRAFTS}
            hasNote={this.props.hasNote}
            labels={this.props.labels}
            actions={this.props.actions}
          />
          <ShareButton
            name={this.props.pageTitle}
            queryString={this.props.queryString}
            description={description}
            image={logoUrl}
            labels={this.props.labels}
            actions={this.props.actions}
            profile={this.props.profile}
            pageKey={AIRCRAFT_PAGE_KEY}
          />
        </DetailFixedTitle>

        <div className="content" ref={this.setScrollableContentEl}>
          <div className="all-informations content-font">
            <Description
              logoFileName={logoUrl}
              description={description}
              isLogoFullWidth={this.props.isLogoFullWidth}
            />

            {/* places */}
            <DetailPlaces
              labels={this.props.labels}
              actions={this.props.actions}
              id={this.props.item.id}
              originalId={this.props.item.original_id}
              entityType={DATA_TYPE_AIRCRAFTS}
              places={this.props.item.references.places}
            />

            {/* presentation statique */}
            {lump.presentationStatique && lump.detailStatique && (
              <div className="message">
                <span dangerouslySetInnerHTML={{ __html: lump.detailStatique }} />
              </div>
            )}

            {/* flights */}
            {/*<DetailEvents
                            count={ flightCount }
                            label={ this.props.labels.data.flights[ flightCount > 1 ? 'plural' : 'singular' ].toLowerCase() }
                            labels={this.props.labels}
                            onClick={ () => {
                                this.props.actions.navigate(
                                    FLIGHTS_SCHEDULE_PAGE_KEY,
                                    {
                                        id: this.props.item.id,
                                    }
                                );
                            }} /> */}

            {/* vol */}
            {lump.presentationVol && flightCount === 0 && (
              <div className="message">
                <span>{this.props.labels.aircraft.messageNoFlightYet}</span>
              </div>
            )}

            {/* information bar */}
            {(this.props.item.references.exhibitor ||
              lump.pilote ||
              lump.copilote ||
              this.props.item.references.country ||
              lump.manufacturer ||
              lump.categoryName ||
              lump.envergure ||
              lump.longueur ||
              lump.tonnage ||
              lump.vitesseMaxCroisiere) && (
              <div className="fiche-separator">{this.props.labels.common.information}</div>
            )}

            {/* exhibitor */}
            <DetailExhibitor
              exhibitor={this.props.item.references.exhibitor}
              actions={this.props.actions}
              labels={this.props.labels}
            />

            {/* Pilote */}
            {lump.pilote && (
              <div className="free-row">
                <div className="prop-img">
                  <div className="prop-left label ">
                    <div>
                      <span>{this.props.labels.aircraft.pilot}</span>
                    </div>
                  </div>
                  <div className="prop-right name-label padding-right">
                    <div>
                      <span>{lump.pilote}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Co-Pilote */}
            {lump.copilote && (
              <div className="free-row">
                <div className="prop-img">
                  <div className="prop-left label">
                    <div>
                      <span>{this.props.labels.aircraft.coPilot}</span>
                    </div>
                  </div>
                  <div className="prop-right  name-label padding-right">
                    <div>
                      <span>{lump.copilote}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Lump-country-id */}
            {this.props.item.references.country && (
              <div className="free-row">
                <div className="prop-img">
                  <div className="prop-left label">
                    <div>
                      <span>{this.props.labels.aircraft.country}</span>
                    </div>
                  </div>
                  <div className="prop-right  name-label padding-right">
                    <div>
                      <span>{this.props.item.references.country.title}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Constructeur */}
            {lump.manufacturer && (
              <div className="free-row">
                <div className="prop-img">
                  <div className="prop-left label">
                    <div>
                      <span>{this.props.labels.aircraft.manufacturer}</span>
                    </div>
                  </div>
                  <div className="prop-right  name-label padding-right">
                    <div>
                      <span>{lump.manufacturer}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* categories */}
            {lump.categoryName && (
              <div className="free-row">
                <div className="prop-img">
                  <div className="prop-left  label">
                    <div>
                      <span>{this.props.labels.aircraft.category}</span>
                    </div>
                  </div>
                  <div className="prop-right  name-label padding-right">
                    <div>
                      <span>{lump.categoryName}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* usage */}
            {this.props.item.utilisation && (
              <div className="free-row">
                <div className="prop-img">
                  <div className="prop-left label">
                    <div>
                      <span>{this.props.labels.aircraft.usage}</span>
                    </div>
                  </div>
                  <div className="prop-right  name-label padding-right">
                    <div>{this.props.item.utilisation}</div>
                  </div>
                </div>
              </div>
            )}

            {/* wingspan */}
            {lump.envergure && (
              <div className="free-row">
                <div className="prop-img">
                  <div className="prop-left label ">
                    <div>
                      <span>{this.props.labels.aircraft.wingSpan}</span>
                    </div>
                  </div>
                  <div className="prop-right  name-label padding-right">
                    <div>
                      <span>
                        {lump.envergure}
                        <span className="mesure">m.</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* aircraft length */}
            {lump.longueur && (
              <div className="free-row">
                <div className="prop-img">
                  <div className="prop-left  label">
                    <div>
                      <span>{this.props.labels.aircraft.length}</span>
                    </div>
                  </div>
                  <div className="prop-right  name-label padding-right">
                    <div>
                      <span>
                        {lump.longueur}
                        <span className="mesure">m.</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* weight */}
            {lump.tonnage && (
              <div className="free-row">
                <div className="prop-img">
                  <div className="prop-left label">
                    <div>
                      <span>{this.props.labels.aircraft.emptyWeight}</span>
                    </div>
                  </div>
                  <div className="prop-right  name-label padding-right">
                    <div>
                      <span>
                        {lump.tonnage}
                        <span className="mesure">kg.</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* speed */}
            {lump.vitesseMaxCroisiere && (
              <div className="free-row high-row">
                <div className="prop-img">
                  <div className="prop-left  label">
                    <div>
                      <span>{this.props.labels.aircraft.maxCruiseSpeed}</span>
                    </div>
                  </div>
                  <div className="prop-right  name-label padding-right">
                    <div>
                      <span>
                        {lump.vitesseMaxCroisiere}
                        <span className="mesure">km/h.</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

AircraftContent.propTypes = {
  isFavorite: PropTypes.bool,
  item: PropTypes.object,
  items: PropTypes.object,
  eventsByDays: PropTypes.object,
  isLogoFullWidth: PropTypes.bool,
  // Common
  queryString: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  labels: PropTypes.object,
  actions: PropTypes.object,
};

export default AircraftContent;
