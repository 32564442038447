import _defineProperty from "/Users/etiennelescot/Documents/mspot-siae2019/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { DATA_TYPE_ANIMATIONS, DATA_TYPE_EXHIBITORS, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_AIRCRAFTS, DATA_TYPE_AIRCRAFT_CATEGORIES, DATA_TYPE_EVENTS, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_DOCUNITS, DATA_TYPE_PARTICIPANTS } from 'data/config/dataConfig'; // Perform search when user has typed at least n characters

export var SEARCH_MIN_CHARS = 2; //export const SEARCH_HIDE_LIST_IF_TOTAL_ABOVE = 10;

/**
 * Data types to look through to find POI on map or itinerary start/destination
 * @type {Array}
 */

export var DATATYPES_WITH_PLACES = [DATA_TYPE_AIRCRAFTS, DATA_TYPE_ANIMATIONS, DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITORS, DATA_TYPE_SERVICES];
export function getSearchType(pageKey, value) {
  if (pageKey !== SEARCH_PAGE_KEY) {
    if (value.length === 1) {
      return SEARCH_TYPES.STARTS_WITH;
    }

    if (value.length === 2) {
      return SEARCH_TYPES.WORD_STARTS_WITH;
    }
  }
} // Common 'title'

var getTitleAttribute = function getTitleAttribute(item) {
  return item.title;
};
/**
 * Expose:
 *  - data types
 *  - and functions returning the value
 * on which search is performed
 */


var conf = function conf(profile) {
  var _config;

  var config = (_config = {}, _defineProperty(_config, DATA_TYPE_AIRCRAFTS, getTitleAttribute), _defineProperty(_config, DATA_TYPE_AIRCRAFT_CATEGORIES, getTitleAttribute), _defineProperty(_config, DATA_TYPE_EXHIBITORS, getTitleAttribute), _defineProperty(_config, DATA_TYPE_EXHIBITOR_CATEGORIES, getTitleAttribute), _defineProperty(_config, DATA_TYPE_NEWPRODUCTS, getTitleAttribute), _defineProperty(_config, DATA_TYPE_NEWPRODUCT_CATEGORIES, getTitleAttribute), _defineProperty(_config, DATA_TYPE_EVENTS, getTitleAttribute), _defineProperty(_config, DATA_TYPE_EVENT_CATEGORIES, getTitleAttribute), _defineProperty(_config, DATA_TYPE_SERVICES, getTitleAttribute), _defineProperty(_config, DATA_TYPE_SERVICE_CATEGORIES, getTitleAttribute), _defineProperty(_config, DATA_TYPE_ANIMATIONS, getTitleAttribute), _defineProperty(_config, DATA_TYPE_PARTICIPANTS, function (item) {
    return item.lastName + ' ' + item.role + ' ' + item.organizationName;
  }), _config);

  if (profile === 'presse') {
    config[DATA_TYPE_DOCUNITS] = getTitleAttribute;
  }

  return config;
};

export var get = conf;