import { EXPERIENCE_ITEM_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import ExperienceItemContent from './ExperienceItemContent';

export default {
  key: EXPERIENCE_ITEM_PAGE_KEY,
  path: '/experience_item',
  elId: DOM_ID,
  className: 'experience-item-page',
  component: GenericItemPage,
  childComponent: ExperienceItemContent,
  relatedDataToFetch: ['places', 'exhibitors', 'events'],
};
