import _defineProperty from "/Users/etiennelescot/Documents/mspot-siae2019/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_AIRCRAFT_CATEGORIES, DATA_TYPE_ANIMATIONS, DATA_TYPE_DOCUNITS } from 'data/config/dataConfig';
import { DEFAULT_PROFILE, ENV, TOU_LINK } from './config';
import * as Db from 'src/core/data-and-assets/Db';
import { startUpdate } from 'src/core/data-and-assets/Updater';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { isIOS, isAndroid, isMobile } from 'src/core/util/browser';
import { createItem, createItemLang, createItemRoute, createItemToggleLocation, createItemTogglePMR } from 'src/core/util/ConfigItemFactory';
import { HOME_PAGE_KEY, FAVORITES_PAGE_KEY, NOTES_PAGE_KEY, INBOX_PAGE_KEY, LIST_PAGE_KEY, MAP_PAGE_KEY, SEARCH_PAGE_KEY, PMR_PAGE_KEY, MEDIAS_PAGE_KEY, CHOOSE_PROFILE_PAGE_KEY } from 'src/pages/pagesKeys';
import { openUrl } from 'src/core/util/JsTools';

var _require = require('./profiles'),
    VISITOR_PRO_PROFILE = _require.VISITOR_PRO_PROFILE,
    VISITOR_GP_PROFILE = _require.VISITOR_GP_PROFILE,
    PRESS_PROFILE = _require.PRESS_PROFILE;

var LOG_PREF = '[menuConfig] '; // documentation: https://material-ui.com/api/swipeable-drawer/#props

export var MENU_OPTIONS = {
  anchor: 'right',
  swipeAreaWidth: isMobile() ? 10 : 0 // default 20

};
var ICON_SIZE = '80%'; // see https://github.com/markusenglund/react-switch#api

export var LOCATION_BUTTON_PROPS = {
  onColor: '#86d3ff',
  onHandleColor: '#2693e6',
  handleDiameter: 22,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 12,
  width: 36
};
export var PMR_BUTTON_PROPS = {
  onColor: '#86d3ff',
  onHandleColor: '#2693e6',
  handleDiameter: 22,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 12,
  width: 36
};
/**
 * Generate an item routing to Home page
 */

var getItemRouteHome = function getItemRouteHome(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'fa fa-home'
    }
  }, // label:
  labels.home.title, // page key:
  HOME_PAGE_KEY);
};
/**
 * Generate an item routing to Exhibitors page
 */


var getItemRouteExhibitors = function getItemRouteExhibitors(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'icon-font',
      webfont: 'd'
    }
  }, // label:
  labels.menu.exhibitorsAndNp, // page key:
  LIST_PAGE_KEY, // page props:
  {
    inputs: [{
      dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
    }, {
      dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
    }]
  });
};
/**
 * Generate an item routing to Services page
 */


var getItemRouteServices = function getItemRouteServices(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'icon-font',
      webfont: 'k' // style: {
      //     backgroundImage: 'url('+getUrl('files/project/home/services.svg')+')',
      //     backgroundSize : ICON_SIZE,
      // }

    }
  }, // label:
  labels.menu.services, // page key:
  LIST_PAGE_KEY, // page props:
  {
    inputs: [{
      dataType: DATA_TYPE_SERVICE_CATEGORIES
    }]
  });
};
/**
 * Generate an item routing to Aircrafts page
 */


var getItemRouteAircrafts = function getItemRouteAircrafts(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'icon-font',
      webfont: 'a'
    }
  }, // label:
  labels.menu.aircraftsAndFlights, // page key:
  LIST_PAGE_KEY, // page props:
  {
    inputs: [{
      dataType: DATA_TYPE_AIRCRAFT_CATEGORIES
    }]
  });
};
/**
 * Generate an item routing to ListGroups page
 */


var getItemRouteAgenda = function getItemRouteAgenda(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'icon-font',
      webfont: 'b'
    }
  }, // label:
  labels.menu.agenda, // page key:
  LIST_PAGE_KEY, // page props:
  {
    inputs: [{
      dataType: DATA_TYPE_EVENT_CATEGORIES
    }]
  });
};
/**
 * Generate an item routing to Map page
 */


var getItemRouteMap = function getItemRouteMap(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'icon-font',
      webfont: 'h' // style: {
      //     backgroundImage: 'url('+getUrl('files/project/home/map.svg')+')',
      //     backgroundSize : ICON_SIZE,
      // }

    }
  }, // label:
  labels.menu.map, // page key:
  MAP_PAGE_KEY);
};
/**
 * Generate a button to toggle PMR status (used for mobigeo routing)
 */


export var getItemTogglePMR = function getItemTogglePMR(labels) {
  return createItemTogglePMR( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/pmr.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.pmr);
};
/**
 * Generate an item routing to Experiences page
 */

var getItemRouteExperiences = function getItemRouteExperiences(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'icon-font',
      webfont: 'c',
      fontSize: '1.2em'
    }
  }, // label:
  labels.menu.experiences, // page key:
  LIST_PAGE_KEY, // page props:
  {
    inputs: [{
      dataType: DATA_TYPE_ANIMATIONS
    }]
  });
};
/**
 * Generate an item routing to Search page
 */


var getItemRouteSearch = function getItemRouteSearch(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'fa fa-search',
      style: {
        lineHeight: '1.3em'
      }
    }
  }, // label:
  labels.search.title, // page key:
  SEARCH_PAGE_KEY);
};
/**
 * Generate an item routing to Favorites page
 */


var getItemRouteFavorites = function getItemRouteFavorites(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'icon-font ',
      webfont: 'e',
      fontSize: '0.5em'
    }
  }, // label:
  labels.favorites.title, // page key:
  FAVORITES_PAGE_KEY);
};
/**
 * Generate an item routing to Favorites page
 */


var getItemRouteNotes = function getItemRouteNotes(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'fa fa-sticky-note',
      style: {
        fontSize: '1.5em',
        lineHeight: '1.3em'
      }
    }
  }, // label:
  labels.notes.pageLinkTitle, // page key:
  NOTES_PAGE_KEY);
};
/**
 * Generate an item routing to Inbox page
 */


var getItemRouteInbox = function getItemRouteInbox(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/inbox.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.inbox, // page key:
  INBOX_PAGE_KEY);
};

var getItemLang = function getItemLang(labels, actions) {
  return createItem({
    icon: {
      className: 'fa fa-lang bottom5',
      style: {
        fontSize: '1.5em',
        lineHeight: '1.3em'
      }
    }
  }, labels.menu.language, actions.showChooseLangDialog);
};

var getItemToggleLocation = function getItemToggleLocation(labels) {
  return createItemToggleLocation( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/location.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.location);
};

var getActionTOU = function getActionTOU(labels, actions) {
  return createItem({
    icon: {
      className: 'fa fa-cgu bottom5',
      style: {
        fontSize: '1.5em',
        lineHeight: '1.3em'
      }
    }
  }, labels.menu.cgu, function () {
    if (openUrl(TOU_LINK[labels.id])) {
      actions.linkClicked(TOU_LINK[labels.id]);
    }
  });
};

var getItemLogin = function getItemLogin(labels, actions) {
  return createItem(null, labels.menu.login, actions.showLoginDialog);
};

var getItemProfile = function getItemProfile(labels, actions) {
  return createItem({
    icon: {
      className: 'fa fa-user',
      style: {
        fontSize: '1.5em',
        lineHeight: '1.3em'
      }
    }
  }, labels.menu.profile, actions.showProfileDialog);
};
/**
 * Generate an item routing to Choose profile page
 */


var getItemRouteChooseProfile = function getItemRouteChooseProfile(labels, profile) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'fa fa-user'
    }
  }, // label:
  (profile ? '<div class="menu-text-current-profile">' + profile + '</div>' : '') + labels.menu.toggleProfile, // page key:
  CHOOSE_PROFILE_PAGE_KEY);
};

var getAdMenuButton = function getAdMenuButton(conf, labels, actions) {
  return !conf ? null : createItem( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl(conf['img_' + labels.id]) + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  conf['name_' + labels.id], // action:
  function () {
    var url = conf['link_' + labels.id];

    if (openUrl(url)) {
      actions.linkClicked(url);
    }
  });
};

var getItemRouteDocunits = function getItemRouteDocunits(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'icon-font',
      webfont: 'p'
    }
  }, // label:
  labels.menu.presskits, // page key:
  LIST_PAGE_KEY, // page props:
  {
    inputs: [{
      dataType: DATA_TYPE_DOCUNITS
    }]
  });
};

var getItemSocialMedia = function getItemSocialMedia(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'icon-font',
      webfont: 'l'
    }
  }, // label:
  labels.menu.medias, // page key:
  MEDIAS_PAGE_KEY, // page props:
  null);
}; // const getItemOpenPageInNativeApp = () => createItem(
//     { icon: {
//         className: 'fab fa-'+(isIOS() ? 'apple' : 'android'),
//         style: {
//             color: '#bababa',
//             fontSize: '2em',
//         }
//     }},
//     // label:
//     'Open in '+(isIOS() ? 'iOS' : 'android')+' app',
//     // action:
//     openCurrentPageInApp,
// );

/**
 * Menu configuration
 */
// const getConfig = (actions, labels, adConfig, twoColumns) => {
//     let conf = {
//         default: [
//             getItemRouteBrands(labels),
//             getItemRouteRestaurants(labels),
//             getItemRouteServices(labels),
//             getItemRouteHours(labels),
//             getItemRouteMap(labels),
//             // Generic:
//             getItemRouteSearch(labels),
//             getItemRouteFavorites(labels),
//             getItemRouteInbox(labels),
//             getItemLang(labels, actions),
//             ENV === 'dev' && global.isCordovaContext !== true && (isIOS() || isAndroid()) ? getItemOpenPageInNativeApp() : null,
//         ]
//     };
//
//     if (adConfig && adConfig.menubuttons) {
//         Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
//             conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions) );
//         });
//     }
//
//     // Filter any empty entry
//     Object.keys(conf).forEach(function(profile) {
//         conf[profile] = conf[profile].filter(menuItemConfig => menuItemConfig);
//     });
//
//     return conf;
// };

/**
 * Menu configuration for PRO profile
 */


var getProConfig = function getProConfig(actions, labels, adConfig, profile) {
  var conf = _defineProperty({
    default: [getItemRouteHome(labels), getItemRouteNotes(labels), getItemRouteFavorites(labels), getItemRouteSearch(labels), getItemRouteAgenda(labels), getItemRouteExhibitors(labels), getItemRouteAircrafts(labels), getItemRouteExperiences(labels), getItemRouteMap(labels), getItemSocialMedia(labels), getItemRouteServices(labels), getItemRouteChooseProfile(labels, profile), getItemLang(labels, actions), getItemToggleLocation(labels), getActionTOU(labels, actions)]
  }, HOME_PAGE_KEY, [getItemRouteNotes(labels), getItemRouteFavorites(labels), getItemRouteSearch(labels), getItemRouteAgenda(labels), getItemRouteExhibitors(labels), getItemRouteAircrafts(labels), getItemRouteExperiences(labels), getItemRouteMap(labels), getItemSocialMedia(labels), getItemRouteServices(labels), getItemRouteChooseProfile(labels, profile), getItemLang(labels, actions), getItemToggleLocation(labels), getActionTOU(labels, actions)]);

  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(function (menuBtnKey) {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
      conf[HOME_PAGE_KEY].push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  } // Filter any empty entry


  Object.keys(conf).forEach(function (page) {
    conf[page] = conf[page].filter(function (menuItemConfig) {
      return menuItemConfig;
    });
  });
  return conf;
};
/**
 * Menu configuration for GP profile
 */


var getGpConfig = function getGpConfig(actions, labels, adConfig, profile) {
  var conf = _defineProperty({
    default: [getItemRouteHome(labels), getItemRouteNotes(labels), getItemRouteFavorites(labels), getItemRouteSearch(labels), getItemRouteAircrafts(labels), getItemRouteExhibitors(labels), getItemRouteExperiences(labels), getItemRouteMap(labels), getItemSocialMedia(labels), getItemRouteServices(labels), getItemRouteChooseProfile(labels, profile), getItemLang(labels, actions), getItemToggleLocation(labels), getActionTOU(labels, actions)]
  }, HOME_PAGE_KEY, [getItemRouteNotes(labels), getItemRouteFavorites(labels), getItemRouteSearch(labels), getItemRouteAircrafts(labels), getItemRouteExhibitors(labels), getItemRouteExperiences(labels), getItemRouteMap(labels), getItemSocialMedia(labels), getItemRouteServices(labels), getItemRouteChooseProfile(labels, profile), getItemLang(labels, actions), getItemToggleLocation(labels), getActionTOU(labels, actions)]);

  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(function (menuBtnKey) {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  } // Filter any empty entry


  Object.keys(conf).forEach(function (profile) {
    conf[profile] = conf[profile].filter(function (menuItemConfig) {
      return menuItemConfig;
    });
  });
  return conf;
};
/**
 * Menu configuration for PRESS profile
 */


var getPressConfig = function getPressConfig(actions, labels, adConfig, profile) {
  var conf = _defineProperty({
    default: [getItemRouteHome(labels), getItemRouteNotes(labels), getItemRouteFavorites(labels), getItemRouteSearch(labels), getItemRouteAgenda(labels), getItemRouteDocunits(labels), getItemRouteExhibitors(labels), getItemRouteAircrafts(labels), getItemRouteExperiences(labels), getItemRouteMap(labels), getItemSocialMedia(labels), getItemRouteServices(labels), getItemRouteChooseProfile(labels, profile), getItemLang(labels, actions), getItemToggleLocation(labels), getActionTOU(labels, actions)]
  }, HOME_PAGE_KEY, [getItemRouteNotes(labels), getItemRouteFavorites(labels), getItemRouteSearch(labels), getItemRouteAgenda(labels), getItemRouteDocunits(labels), getItemRouteExhibitors(labels), getItemRouteAircrafts(labels), getItemRouteExperiences(labels), getItemRouteMap(labels), getItemSocialMedia(labels), getItemRouteServices(labels), getItemRouteChooseProfile(labels, profile), getItemLang(labels, actions), getItemToggleLocation(labels), getActionTOU(labels, actions)]);

  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(function (menuBtnKey) {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
      conf[HOME_PAGE_KEY].push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  } // Filter any empty entry


  Object.keys(conf).forEach(function (page) {
    conf[page] = conf[page].filter(function (menuItemConfig) {
      return menuItemConfig;
    });
  });
  return conf;
};
/**
 * Return menu configuration
 * @param  {string} profile
 * @return {object}
 */


export var get = function get() {
  var profile = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_PROFILE;
  var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';
  var actions = arguments.length > 2 ? arguments[2] : undefined;
  var labels = arguments.length > 3 ? arguments[3] : undefined;
  var adConfig = arguments.length > 4 ? arguments[4] : undefined;
  var twoColumns = arguments.length > 5 ? arguments[5] : undefined;
  var conf; // Get config depending on profile

  switch (profile) {
    case VISITOR_PRO_PROFILE:
      conf = getProConfig(actions, labels, adConfig, labels.profile[profile]);
      break;

    case VISITOR_GP_PROFILE:
      conf = getGpConfig(actions, labels, adConfig, labels.profile[profile]);
      break;

    case PRESS_PROFILE:
      conf = getPressConfig(actions, labels, adConfig, labels.profile[profile]);
      break;

    default:
      console.error(LOG_PREF + 'Unsupported profile', profile);
  } // Return config depending on page


  if (conf) {
    return conf[page] || conf.default;
  } // else undefined is returned

};