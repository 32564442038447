// Libs
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Masonry from 'react-masonry-component';

// App modules
import { apply as applyTileClickEffect } from 'src/core/util/TileClickEffect';
import { getValueFromStringAttribute } from 'src/core/util/JsTools';
import { resetSheet, setCssRules, getCssClassName } from './HomeTilesCssHandler';

import './HomeTiles.scss';

// #home-content element padding
const HOMECONTENT_PADDING = 3;

// @see http://masonry.desandro.com/options.html
const masonryOptions = {
  itemSelector: '.home-tile',
  columnWidth: '.home-tile-width1',

  // Items will move slightly one after another
  stagger: 60,
};

/**
 * Divide available width by column count.
 * If column width is > `maxColumnSize`, increment column count until width is < `maxColumnSize`.
 * @param  {number} baseColumnCount
 * @param  {number} maxColumnSize
 * @return {number}
 */
const getColumnWidth = (baseColumnCount, maxColumnSize) => {
  const availableWidth = document.documentElement.clientWidth - HOMECONTENT_PADDING * 2;

  let neededColumnsCount = baseColumnCount - 1;
  let colWidth;
  do {
    neededColumnsCount++;
    colWidth = Math.floor(availableWidth / neededColumnsCount);
  } while (colWidth > maxColumnSize);

  return colWidth;
};

const getStyle = (key, styleConf) => {
  const hasStyle = styleConf && typeof styleConf[key] === 'object' && styleConf[key] !== null;

  let style = null;
  if (hasStyle && styleConf[key].style) {
    style = styleConf[key].style;
  }

  const className = [];
  if (hasStyle && styleConf[key].className) {
    className.push(styleConf[key].className);
  }

  return {
    hasStyle,
    style,
    className,
  };
};

/**
 * Component
 */
class HomeTiles extends PureComponent {
  render() {
    const columnWidth = getColumnWidth(this.props.baseColumnCount, this.props.maxColumnSize);

    // In case of window resize, any previously calculated rule should be removed
    resetSheet();

    // Skip invalid tiles (e.g null if a condition for a component to be present was not met)
    const _tiles = this.props.tiles.filter((tile) => tile);

    // Set CSS rules for the tiles (once is enough)
    setCssRules(_tiles, columnWidth);

    return (
      <Masonry className="home-tiles" options={masonryOptions}>
        {_tiles.map((entry, index) => {
          const tileClassName = ['home-tile'];
          tileClassName.push(getCssClassName('width', entry.options.width));
          tileClassName.push(getCssClassName('height', entry.options.height));

          const onClick = (e) => {
            entry.action(null, this.props.actions);
            applyTileClickEffect(e.target, 'home-tile-subcontainer', () => {});
          };

          // Container style
          const { style: containerStyle, className: containerClassName } = getStyle(
            'container',
            entry.style
          );
          containerClassName.push('home-tile-subcontainer');

          // Icon style
          const { hasStyle: iconHasStyle, style: iconStyle, className: iconClassName } = getStyle(
            'icon',
            entry.style
          );
          iconClassName.push('home-tile-legend-icon');

          // Label style
          const { style: labelStyle, className: labelClassName } = getStyle('label', entry.style);
          labelClassName.push('home-tile-legend-label');

          return (
            <span key={index} className={tileClassName.join(' ')} onClick={onClick}>
              <span className={containerClassName.join(' ')} style={containerStyle}>
                <span className="home-tile-legend">
                  {iconHasStyle && (
                    <span className={iconClassName.join(' ')} style={iconStyle}>
                      {iconHasStyle && typeof entry.style.icon.webfont === 'string'
                        ? entry.style.icon.webfont
                        : null}
                    </span>
                  )}
                  <span className={labelClassName.join(' ')} style={labelStyle}>
                    {getValueFromStringAttribute(this.props.labels, entry.label) || entry.label}
                  </span>
                </span>

                <span className="effect-container" />
              </span>
            </span>
          );
        })}
      </Masonry>
    );
  }
}

HomeTiles.propTypes = {
  baseColumnCount: PropTypes.number.isRequired,
  maxColumnSize: PropTypes.number.isRequired,
  tiles: PropTypes.array.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default HomeTiles;
