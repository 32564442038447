var defaultConf = require('./config-default');

var NODE_BACKEND_DOMAIN = 'siae2019-node-backend-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: 'siae2019-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_PATH: '/home/www/siae/siae2019/siae2019-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '~/siae/siae2019/siae2019-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-10',
  projectId: 205278861054,
  // GCM/FCM sender id
  appId: '93529-38D3E',
  // pushwoosh appid
  SOCIAL: {
    FEATURE_ENABLED: true,
    // FACEBOOK: {
    //     POST_PER_PAGE: 5
    // },
    TWITTER: {
      POST_PER_PAGE: 10
    },
    INSTAGRAM: {
      POST_PER_PAGE: 5
    },
    YOUTUBE: {
      POST_PER_PAGE: 5
    },
    // LINKEDIN: {
    //     POST_PER_PAGE: 10
    // },
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social")
  },
  LOGIN: {
    WS_URL: 'https://blockchain2020.site.calypso-event.net/testSDA'
  },
  USER_DATA: {
    WS_URL: 'https://blockchain2020.site.calypso-event.net/testSDA'
  },
  PARTICIPANTS: {
    WS_URL: 'https://blockchain2020.site.calypso-event.net/testSDA'
  }
});