import {
  CONTACTS_UPDATED,
  CONTACTS_SCAN_UNAUTHORISED,
  SET_LOGIN_STATUS,
} from 'src/store/actionTypes';
import { USER_DATA_PAGE_KEY, LOGIN_PAGE_KEY } from 'src/pages/pagesKeys';
import { navigate } from 'src/store/actions';
import { getBindedActions } from 'src/store/bindedActions';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { clearData } from 'src/core/contacts/Contacts';

import { get as getLabels } from 'src/core/Lang';
import { getCurrentPageKey, getCurrentPageProps } from 'src/core/navigation/CurrentRoute';

export default ({ dispatch, getState }) => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case CONTACTS_UPDATED:
      dispatch(
        navigate(USER_DATA_PAGE_KEY, {
          tabIndex: 'metContacts',
        })
      );
      break;

    case CONTACTS_SCAN_UNAUTHORISED:
      getBindedActions().showNotification({
        message: getLabels().contacts.loginNeeded,
        level: NotificationLevels.WARNING,
      });
      dispatch(
        navigate(LOGIN_PAGE_KEY, {
          nextAction: { name: 'scanContact', params: {} },
          nextRoute: { pageKey: getCurrentPageKey(), pageProps: getCurrentPageProps() },
        })
      );

    case SET_LOGIN_STATUS:
      if (!action.loggedIn) {
        clearData();
      }
      break;

    default:
      break;
  }

  return result;
};
