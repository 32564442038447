import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { LOGIN_PAGE_KEY } from 'src/pages/pagesKeys';

import { DATA_TYPE_EXHIBITORS, DATA_TYPE_DOCUNITS } from 'data/config/dataConfig';

import { DOCUNIT_PAGE_KEY } from 'src/pages/pagesKeys';

import { isAndroid } from 'src/core/util/browser';

import DetailSocial from 'src/components/detail-social/DetailSocial';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import ShareButton from 'src/components/share-button/ShareButton';
import LinkButton from 'src/components/link-button/LinkButton';
import Description from 'src/components/fiche/Description';
import PhoneRow from 'src/components/fiche/PhoneRow';
import EmailRow from 'src/components/fiche/EmailRow';
import Url from 'src/components/url/Url';
import NoteButton from 'src/components/notes/NoteButton';

import '../common-fiche.scss';
import './DocunitPage.scss';

class DocunitContent extends PureComponent {
  setScrollableContentEl = (el) => {
    this.contentContainerEl = el;
  };
  scrollTo = (value) => {
    if (this.contentContainerEl) {
      this.contentContainerEl.scrollTop = value;
    }
  };

  onClickOnFavIcon = () => {
    this.props.actions.toggleFavorite(
      this.props.item.id,
      DATA_TYPE_DOCUNITS,
      this.props.isFavorite
    );
  };

  render() {
    const title = this.props.item.title,
      description = this.props.item.description,
      lump = this.props.item.lump,
      image = this.props.item.image,
      hasSocial = lump.social && (lump.social.tw || lump.social.fb || lump.social.ln),
      references = this.props.item.references;

    return (
      <div className="fiche docunit content-font content-below-apptoolbar">
        {/* title */}
        <DetailFixedTitle
          title={title}
          isFav={this.props.isFavorite}
          onFavClick={this.onClickOnFavIcon}
          labels={this.props.labels}
        >
          <NoteButton
            itemTitle={this.props.item.title}
            itemId={this.props.item.id}
            dataType={DATA_TYPE_DOCUNITS}
            hasNote={this.props.hasNote}
            labels={this.props.labels}
            actions={this.props.actions}
          />
          <ShareButton
            name={this.props.pageTitle}
            queryString={this.props.queryString}
            description={description}
            labels={this.props.labels}
            actions={this.props.actions}
            profile={this.props.profile}
            pageKey={DOCUNIT_PAGE_KEY}
          />
        </DetailFixedTitle>

        <div className="content" ref={this.setScrollableContentEl}>
          <div className="all-informations content-font">
            <div className="docunit-description">
              {references && references.parent && (
                <LinkButton
                  config={{
                    id: references.parent.id,
                    type: DATA_TYPE_EXHIBITORS,
                    label: references.parent.title,
                  }}
                  actions={this.props.actions}
                  labels={this.props.labels}
                  displayType
                />
              )}

              <Description logoFileName={image} description={description} />
            </div>

            <div className="fiche-separator">{this.props.labels.docunit.contacts}</div>

            {/* social */}
            {hasSocial && (
              <div className="contact-row">
                <DetailSocial data={lump.social} actions={this.props.actions} />
              </div>
            )}

            {/* contacts */}
            <div>
              {lump.contacts &&
                lump.contacts.length > 0 &&
                lump.contacts.map((contact) => (
                  <div key={contact.name} className="contact-row">
                    <div className="free-row">
                      <div className="prop-img">
                        <div className="prop-left">
                          <div className="fiche-contact-icon">
                            <span className="fa fa-user" />
                          </div>
                        </div>
                        <div className="prop-right vertical-center">
                          <div className="docunit-contact-detail docunit-contact-name">
                            {contact.name}
                          </div>
                          <div className="docunit-contact-detail docunit-contact-role">
                            {contact.role}
                          </div>
                        </div>
                      </div>
                    </div>

                    {contact.organisation && (
                      <div className="docunit-contact-detail docunit-contact-organisation">
                        {contact.organisation}
                      </div>
                    )}
                    {contact.tel && <PhoneRow phone={contact.tel} />}
                    {contact.email && <EmailRow email={contact.email} />}
                  </div>
                ))}
            </div>

            {references.documents && references.documents.length > 0 && (
              <div className="fiche-separator">{this.props.labels.docunit.documents}</div>
            )}

            {/* documents */}
            {references.documents &&
              references.documents.length > 0 &&
              references.documents.map((document) => (
                <div key={document.id} className="activity free-row docunit-document">
                  <div className="docunit-document-header">
                    <h2>{document.title}</h2>
                  </div>
                  <div className="docunit-document-body">
                    <p className="docunit-document-description">{document.description}</p>
                    <div className="docunit-document-details">
                      {document.type && (
                        <span className="docunit-document-type">{document.type}</span>
                      )}
                      {document.lang && (
                        <span className="docunit-document-lang">{document.lang}</span>
                      )}
                    </div>
                    <div>
                      <Url
                        beforeAction={() => {
                          if (document.restricted && !this.props.isLoggedIn) {
                            this.props.actions.navigate(LOGIN_PAGE_KEY);
                            return false;
                          }
                          return true;
                        }}
                        href={document.link}
                        target={global.isCordovaContext && isAndroid() ? '_system' : '_blank'}
                      >
                        <div className="link-btn-container">
                          <div className="link-btn">
                            <span className="link-btn-label">
                              {this.props.labels.docunit.document}
                            </span>
                            <span className="link-btn-icon fa fa-chevron-right" />
                          </div>
                        </div>
                      </Url>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {/* end of .allInformations */}
        </div>
      </div>
    );
  }
}

DocunitContent.propTypes = {
  item: PropTypes.object,
  isFavorite: PropTypes.bool,
  // Common
  queryString: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default DocunitContent;
