import { AIRCRAFT_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import AircraftContent from './AircraftContent';

export default {
  key: AIRCRAFT_PAGE_KEY,
  path: '/aircraft',
  elId: DOM_ID,
  className: 'aircraft-page',
  component: GenericItemPage,
  childComponent: AircraftContent,
  relatedDataToFetch: ['country', 'exhibitor', 'places'],
};
