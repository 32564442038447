// NB: to build a cordova app, relative path is needed here
var _require = require('../../src/pages/pagesKeys'),
    HOME_PAGE_KEY = _require.HOME_PAGE_KEY;

var _require2 = require('../../src/core/webservices/WsProviders'),
    TAIGA = _require2.TAIGA;

var projectConfig = require('../../../data/config.js');

var _require3 = require('./profiles'),
    VISITOR_PRO_PROFILE = _require3.VISITOR_PRO_PROFILE,
    VISITOR_GP_PROFILE = _require3.VISITOR_GP_PROFILE,
    PRESS_PROFILE = _require3.PRESS_PROFILE;

var _require4 = require('./dataConfig'),
    DATA_TYPE_EVENTS = _require4.DATA_TYPE_EVENTS; // Only export a default object, to be able to
// override properties depending on target environment


module.exports = {
  // Misc UI
  DELAY_BEFORE_CLICK_ACTION: 50,
  //ms
  SHOW_TERMS_OF_USE_ON_HOME: true,
  // Terms of use URL
  TOU_LINK: {
    fr: 'http://kim1.mobile-spot.com/siae2019/cgu/cgu_fr.html',
    en: 'http://kim1.mobile-spot.com/siae2019/cgu/cgu_en.html'
  },
  // App profiles
  SUPPORTED_PROFILES: [VISITOR_PRO_PROFILE, VISITOR_GP_PROFILE, PRESS_PROFILE],
  //DEFAULT_PROFILE   : VISITOR_PRO_PROFILE,
  // Routing (in two columns mode, = default page on left column)
  getHomePage: function getHomePage(profile) {
    return {
      pageKey: HOME_PAGE_KEY,
      props: null
    };
  },
  APP_TITLE_SEPARATOR: ' - ',
  ROUTE_SEPARATOR: '?',
  // Lang
  SUPPORTED_LANGS: ['fr', 'en'],
  DEFAULT_LANG: 'en',
  // Backend / Updates
  UPDATE_ENABLED: true,
  BO_SSH_USER: 'www',
  LIST_GROUPS_SHOW_SIDEINDEX_IF_ABOVE: 20,
  // Map
  MOBIGEO_API_KEY: 'dem7eKah',
  // Mobile-Spot link
  MOBILESPOT_WWW: 'http://www.mobile-spot.com',
  // SHARE:
  DEFAULT_SHARE_IMAGE: '',
  //  on LinkedIn
  LINKEDIN_API_KEY: '',
  //  on Facebook
  FACEBOOK_APP_ID: '',
  WEB_URL: projectConfig.web.url,
  // In web mode, this fallback url can be used if the user's
  // browser does not meet some requirements (eg IE<11)
  WEB_FALLBACK: '',
  // e.g com.printemps.haussmann.dev
  NATIVE_ID: projectConfig.native.id,

  /**
   * FEATURES
   */
  APPOINTMENT_REQUEST: {
    FEATURE_ENABLED: false // webservice url is defined per environment

  },
  CONTRIBUTIONS_FEED: {
    FEATURE_ENABLED: false
  },
  FLIGHTS_SCHEDULE: {
    FEATURE_ENABLED: true
  },
  LOGIN: {
    FEATURE_ENABLED: false,
    PROVIDER: TAIGA,
    // Note: webservice url is defined per environment
    forgottenPasswordURL: null,
    createAccountURL: null,
    tosURL: null
  },
  NOTES: {
    FEATURE_ENABLED: false
  },
  POLL: {
    FEATURE_ENABLED: false
  },
  USER_DATA: {
    FEATURE_ENABLED: false,
    PROVIDER: TAIGA,
    FIELDS: {}
  },
  PARTICIPANTS: {
    PROVIDER: TAIGA,
    DATA_EXPIRE_DELAY: 1 * 60 * 1000
  },
  SHARE: {
    FEATURE_ENABLED: true,
    POSITION_SHARE_IMAGE: '',
    // Declare restrictions
    // If current page + current profile are found below,
    // then share button is hidden.
    RESTRICTIONS: [// e.g
      // { pageKey: EXHIBITOR_PAGE_KEY,
      //   for : [ VISITOR_GP_PROFILE ]
      // }
    ]
  },
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    // webservice url is defined per environment
    // Debouncing value represents the delay between
    // last favorite toggle (add or remove) and server call
    DEBOUNCING: 10 * 1000 // ms

  },
  SYNOPTIC_AGENDA: {
    DEFAULT_INPUT: {
      agendaProps: {
        input: {
          dataType: DATA_TYPE_EVENTS
        }
      }
    }
  },
  SOCIAL: {
    FEATURE_ENABLED: false
  },
  IMAGE_UPLOAD: {
    // see https://github.com/blueimp/JavaScript-Load-Image#options
    LOAD_IMAGE_OPTIONS: {
      maxWidth: 900,
      maxHeight: 900,
      contain: false,
      orientation: true,
      canvas: true
    },
    INPUT_ACCEPTED_MIME: 'image/*',
    OUTPUT_MIME: 'image/jpeg',
    OUTPUT_COMPRESSION: 0.92,
    DISPLAY_IMAGE_OPTIONS: {
      maxWidth: 300,
      maxHeight: 300,
      cropMinWidth: 100,
      cropMinHeight: 100,
      cropInitialWidth: 300,
      cropRatio: 0.85
    }
  },
  NETWORKING: {
    FEATURE_ENABLED: false
  }
};