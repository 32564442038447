import config from 'data/config/config';
import configureStatusBar from 'data/config/cordovaStatusBarConfig';

import getInAppBrowserOptions from 'data/config/inAppBrowserOptions';
import { isAndroid, isIOS, getAndroidVersion } from 'src/core/util/browser';
import * as Lang from 'src/core/Lang';
import { get as getProfile } from 'src/core/Profile';
import { parseUrl, queuedNavigation } from 'src/core/navigation/Router';
import { addKeyBoardListeners } from 'src/core/keyboard/KeyboardHelper';
import { getBindedActions } from 'src/store/bindedActions';
import { getTimeOfLastScanWithoutResult } from './cordovaReducerAndMiddleware';

const LOG_PREF = '[CordovaHelper] ';
export function init() {
  if (global.isCordovaContext !== true) {
    return;
  }
  addKeyBoardListeners();

  initMobileInfo();

  // Cookie issue on iOS/wkWebview
  // See https://github.com/ionic-team/cordova-plugin-ionic-webview/issues/22
  if (isIOS()) {
    if (!global.wkWebView) {
      console.error(
        `${LOG_PREF}'wkWebView' global is missing, it should be provided by plugin cordova-plugin-wkwebview-inject-cookie`
      );
    } else {
      const urls = [];
      function addUrl(url) {
        if (urls.indexOf(url) === -1) {
          urls.push(url);
        }
      }
      if (config.BO_TAIGA_WS_URL) {
        addUrl(config.BO_TAIGA_WS_URL);
      }

      urls.forEach((url) => {
        console.log(`${LOG_PREF}Calling cordova plugin to handle cookie for url: ${url}`);
        global.wkWebView.injectCookie(url);
      });
    }
  }

  // ANDROID BACK BUTTON BEHAVIOUR
  document.addEventListener(
    'backbutton',
    (e) => {
      // see SAN-17
      if (
        getTimeOfLastScanWithoutResult() &&
        new Date().getTime() - getTimeOfLastScanWithoutResult() < 1500
      ) {
        console.info(`${LOG_PREF}ignoring back button event`);
        return;
      }
      console.info(`${LOG_PREF}Back button triggered`);
      getBindedActions().navigateBack();
    },
    false
  );

  // IN APP BROWSER
  if (cordova.InAppBrowser) {
    const nativeOpen = window.open;
    window.open = function(url, target) {
      const options = getInAppBrowserOptions(isAndroid() ? 'android' : 'ios', Lang.get());

      // target option to bypass inappbrowser
      if (target === '_native') {
        nativeOpen(url, '_blank');
      } else {
        cordova.InAppBrowser.open(url, target, options);
      }
    };
  }

  /**
   * Handle when app is opened via an url
   * e.g http://ph-dev.mobile-spot.com/?/service&originalId=5c442084-9ee7-e811-80d9-005056ae0696&lang=fr&profile=default
   */
  if (window.mobilespotApp && typeof window.mobilespotApp.getIntent === 'function') {
    function getIntentAndNavigate() {
      window.mobilespotApp.getIntent(
        function getIntentSuccessCb(intentUri) {
          if (intentUri) {
            console.log(`${LOG_PREF}App started with intent url: ${intentUri}`);
            queuedNavigation.set(() => parseUrl(intentUri));
          }
        },
        function getIntentFailureCb(...args) {
          console.error(`${LOG_PREF}Failed to execute window.mobilespotApp.getIntent`, args);
        }
      );
    }

    document.addEventListener('onIntent', function(event) {
      console.info(`${LOG_PREF}onIntent event received ;)`, event);
      getIntentAndNavigate();
    });

    // Check if app has been started with query parameters
    getIntentAndNavigate();
  }
}

export function customizeStatusBar(pageKey) {
  if (!global.isCordovaContext) {
    return;
  }
  if (!global.StatusBar) {
    // console.log(LOG_PREF+'No status bar plugin installed');
  } else if (typeof configureStatusBar !== 'function') {
    // console.log(LOG_PREF+'No status bar configuration found');
  } else {
    const androidVersion = getAndroidVersion();
    if (androidVersion && androidVersion < 6) {
      // Android 5 does not really support status bar styling
      return;
    }
    configureStatusBar(pageKey);
  }
}

let mobileId;
let model;
let mobileName;

function initMobileInfo() {
  if (!global.device) {
    console.error(`${LOG_PREF}cordova-plugin-device is missing`);
    return;
  }
  mobileId = global.device.uuid;
  model = global.device.model;
  mobileName = cordova.plugins.deviceName ? cordova.plugins.deviceName.name : model;
}

export const getMobileId = () => mobileId;
export const getMobileModel = () => model;
export const getMobileName = () => mobileName;
