import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_AIRCRAFT_CATEGORIES, DATA_TYPE_ANIMATIONS, DATA_TYPE_DOCUNITS } from 'data/config/dataConfig'; // app modules

import { LIST_PAGE_KEY, LOGIN_PAGE_KEY, MAP_PAGE_KEY, MEDIAS_PAGE_KEY, PMR_PAGE_KEY } from 'src/pages/pagesKeys';
import AdSwap from 'src/components/ad-swap/AdSwap';
import Banner from 'src/components/banner/Banner';
import HomeToolbar from 'src/pages/home/HomeToolbar';
import HomeTiles from 'src/pages/home/HomeTiles';
import MobilespotBar from 'src/components/mobilespot-bar/MobilespotBar';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { createItem, createItemRoute } from 'src/core/util/ConfigItemFactory';
import { openUrl } from 'src/core/util/JsTools';
import { AD_BUNDLE_ATTRIBUTION_KEYS } from 'src/core/config-json/ConfigJsonManager';
var LOG_PREF = '[homeConfig] ';

var _require = require('./profiles'),
    VISITOR_PRO_PROFILE = _require.VISITOR_PRO_PROFILE,
    VISITOR_GP_PROFILE = _require.VISITOR_GP_PROFILE,
    PRESS_PROFILE = _require.PRESS_PROFILE; // NB: used for ChooseProfile page too !


export var getStyle = function getStyle() {
  return {
    //     background: `url(${getUrl('files/project/home/background_tile.png')}) repeat`,
    backgroundImage: 'linear-gradient(#122d62, #122d62, #122d62, #0088c9)'
  };
};
export var BASE_COLUMN_COUNT = {
  landscape: 4,
  portrait: 4
};
export var MAX_COLUMN_SIZE = {
  landscape: 190,
  // 2 columns mode => less width available
  portrait: 190
};
export var GENERAL_TILE_OPTIONS = {
  height: 1,
  // rowspan
  width: 1 // colspan

}; // TEMP FIXME (use jsontosass-loader ?)

var color1 = '#005EA7';
var color2 = '#122D62';

var createItemAd = function createItemAd(homebutton, lang, backgroundPosition) {
  return createItem( // Style
  {
    container: {
      style: {
        background: "url(".concat(getUrl(homebutton["img_".concat(lang)]), ") no-repeat ").concat(backgroundPosition || '50% 50%', " ").concat(homebutton.bgcolor),
        backgroundSize: 'contain'
      }
    },
    icon: {
      className: 'home-tile-ad'
    }
  }, // Label (i18n)
  '', // Action to perform on click
  function (actions) {
    var url = homebutton['link_' + lang];

    if (openUrl(url)) {
      actions.linkClicked(url);
    }
  }, // Tile options
  {
    height: 1,
    width: 1
  });
};

var getPRO = function getPRO(profile, lang, adConfig, orientation, isLoggedIn) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [// Exhibitors
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/visuel_EXH2.jpg') + ')',
            backgroundPositionX: '100%'
          },
          className: 'home-btn-icon-and-text-upper-left'
        },
        icon: {
          className: 'icon-font',
          webfont: 'd'
        }
      }, // Label (i18n)
      'home.exhibitorsAndProducts', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: '',
        inputs: [{
          dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
        }, {
          dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
        }]
      }, // Tile options
      {
        height: 2,
        width: 2
      }), // Agenda (hors vols)
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'linear-gradient(#00a096, #10b0a6)'
          }
        },
        icon: {
          className: 'icon-font',
          webfont: 'b'
        }
      }, // Label (i18n)
      'home.agenda', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: '',
        inputs: [{
          dataType: DATA_TYPE_EVENT_CATEGORIES
        }]
      }, // Tile options
      {
        height: 1,
        width: 1
      }), // Social Medias
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'linear-gradient(#1291D9, #40A6D9)',
            color: 'white',
            fontSize: '.9em'
          }
        },
        icon: {
          style: {},
          className: 'icon-font',
          webfont: 'l'
        }
      }, // Label (i18n)
      'home.medias', // Page & props
      MEDIAS_PAGE_KEY, null, // Tile options
      {
        height: 1,
        width: 1
      }), // Map
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/visuel_MAP.jpg') + ')',
            color: color2
          },
          className: 'home-btn-icon-and-text-upper-left'
        },
        icon: {
          className: 'icon-font',
          webfont: 'h'
        }
      }, // Label (i18n)
      'home.map', // Page & props
      MAP_PAGE_KEY, null, // Tile options
      {
        height: 1,
        width: 2
      }), // Aircrafts
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/visuel_AERO_PRO.jpg') + ')',
            backgroundPosition: '95% 0'
          },
          className: 'home-btn-icon-and-text-upper-left'
        },
        icon: {
          className: 'icon-font',
          webfont: 'a'
        }
      }, // Label (i18n)
      'home.aircraftsAndFlights', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: '',
        inputs: [{
          dataType: DATA_TYPE_AIRCRAFT_CATEGORIES
        }]
      }, // Tile options
      {
        height: 1,
        width: 2
      }), // Experiences
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundColor: 'white',
            backgroundImage: 'url(' + getUrl('files/project/home/visuel_ANIMATIONS_GP2.jpg') + ')',
            color: 'white'
          },
          className: 'home-btn-icon-and-text-lower-left-bg-black'
        },
        icon: {
          className: 'icon-font',
          webfont: 'c'
        }
      }, // Label (i18n)
      'home.experiences', LIST_PAGE_KEY, {
        contextualTitle: '',
        inputs: [{
          dataType: DATA_TYPE_ANIMATIONS
        }]
      }, // Tile options
      {
        height: 2,
        width: 2
      }), // PMR
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundColor: color1
          }
        },
        icon: {
          className: 'icon-font',
          webfont: 'i'
        }
      }, // Label (i18n)
      'home.pmr', // Page & props
      PMR_PAGE_KEY, null, // Tile options
      {
        height: 1,
        width: 1
      }), // Services
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'linear-gradient(#ff0614, #ff5664)'
          }
        },
        icon: {
          style: {},
          className: 'icon-font',
          webfont: 'k'
        }
      }, // Label (i18n)
      'home.practicalInfo', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: '',
        inputs: [{
          dataType: DATA_TYPE_SERVICE_CATEGORIES
        }]
      }, // Tile options
      {
        height: 1,
        width: 1
      })]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};

var getGP = function getGP(profile, lang, adConfig, orientation, isLoggedIn) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [// Aircrafts
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/visuel_AERO_GP.jpg') + ')',
            backgroundPosition: '74% 0'
          },
          className: 'home-btn-icon-and-text-upper-left'
        },
        icon: {
          className: 'icon-font',
          webfont: 'a'
        }
      }, // Label (i18n)
      'home.aircraftsAndFlights', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: '',
        inputs: [{
          dataType: DATA_TYPE_AIRCRAFT_CATEGORIES
        }]
      }, // Tile options
      {
        height: 2,
        width: 2
      }), // Map
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/visuel_MAP.jpg') + ')',
            color: color2
          },
          className: 'home-btn-icon-and-text-upper-left'
        },
        icon: {
          className: 'icon-font',
          webfont: 'h'
        }
      }, // Label (i18n)
      'home.map', // Page & props
      MAP_PAGE_KEY, null, // Tile options
      {
        height: 1,
        width: 2
      }), // Social Medias
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'linear-gradient(#00a096, #10b0a6)',
            color: 'white'
          }
        },
        icon: {
          style: {},
          className: 'icon-font',
          webfont: 'l'
        }
      }, // Label (i18n)
      'home.medias', // Page & props
      MEDIAS_PAGE_KEY, null, // Tile options
      {
        height: 1,
        width: 1
      }), // Mascotte
      hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null, // Exhibitors
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/visuel_EXH_GP2.jpg') + ')',
            color: color2
          },
          className: 'home-btn-icon-and-text-upper-left-bg-white'
        },
        icon: {
          className: 'icon-font',
          webfont: 'd'
        }
      }, // Label (i18n)
      'home.exhibitorsAndProducts', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: '',
        inputs: [{
          dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
        }, {
          dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
        }]
      }, // Tile options
      {
        height: 1,
        width: 2
      }), // Experiences
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundColor: 'white',
            backgroundImage: 'url(' + getUrl('files/project/home/visuel_ANIMATIONS_GP2.jpg') + ')',
            color: 'white'
          },
          className: 'home-btn-icon-and-text-lower-left-bg-black'
        },
        icon: {
          className: 'icon-font',
          webfont: 'c'
        }
      }, // Label (i18n)
      'home.experiences', LIST_PAGE_KEY, {
        contextualTitle: '',
        inputs: [{
          dataType: DATA_TYPE_ANIMATIONS
        }]
      }, // Tile options
      {
        height: 2,
        width: 2
      }), // Services
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundColor: '#FF0614'
          }
        },
        icon: {
          style: {},
          className: 'icon-font',
          webfont: 'k'
        }
      }, // Label (i18n)
      'home.practicalInfo', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: '',
        inputs: [{
          dataType: DATA_TYPE_SERVICE_CATEGORIES
        }]
      }, // Tile options
      {
        height: 1,
        width: 1
      }), // PMR
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundColor: color1
          }
        },
        icon: {
          className: 'icon-font',
          webfont: 'i'
        }
      }, // Label (i18n)
      'home.pmr', // Page & props
      PMR_PAGE_KEY, null, // Tile options
      {
        height: 1,
        width: 1
      })]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};

var getPRESS = function getPRESS(profile, lang, adConfig, orientation, isLoggedIn) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [// Exhibitors
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/visuel_EXH2.jpg') + ')',
            backgroundPositionX: '100%'
          },
          className: 'home-btn-icon-and-text-upper-left'
        },
        icon: {
          className: 'icon-font',
          webfont: 'd'
        }
      }, // Label (i18n)
      'home.exhibitorsAndProducts', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: '',
        inputs: [{
          dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
        }, {
          dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
        }]
      }, // Tile options
      {
        height: 2,
        width: 2
      }), // Agenda (hors vols)
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'linear-gradient(#00a096, #10b0a6)'
          }
        },
        icon: {
          className: 'icon-font',
          webfont: 'b'
        }
      }, // Label (i18n)
      'home.agenda', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: '',
        inputs: [{
          dataType: DATA_TYPE_EVENT_CATEGORIES
        }]
      }, // Tile options
      {
        height: 1,
        width: 1
      }), // Casiers presse
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'linear-gradient(#ff0614, #ff5664)',
            fontSize: '.9em'
          }
        },
        icon: {
          style: {},
          className: 'icon-font',
          webfont: 'p'
        }
      }, // Label (i18n)
      'home.presskits', LIST_PAGE_KEY, {
        contextualTitle: '',
        inputs: [{
          dataType: DATA_TYPE_DOCUNITS
        }]
      }, // Tile options
      {
        height: 1,
        width: 1
      }), // Map
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/visuel_MAP.jpg') + ')',
            color: color2
          },
          className: 'home-btn-icon-and-text-upper-left'
        },
        icon: {
          className: 'icon-font',
          webfont: 'h'
        }
      }, // Label (i18n)
      'home.map', // Page & props
      MAP_PAGE_KEY, null, // Tile options
      {
        height: 1,
        width: 1
      }), // Social Medias
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'linear-gradient(#1291D9, #40A6D9)',
            color: 'white',
            fontSize: '.9em'
          }
        },
        icon: {
          style: {},
          className: 'icon-font',
          webfont: 'l'
        }
      }, // Label (i18n)
      'home.medias', // Page & props
      MEDIAS_PAGE_KEY, null, // Tile options
      {
        height: 1,
        width: 1
      }), // Aircrafts
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/visuel_AERO_PRO.jpg') + ')',
            backgroundPosition: '95% 0'
          },
          className: 'home-btn-icon-and-text-upper-left'
        },
        icon: {
          className: 'icon-font',
          webfont: 'a'
        }
      }, // Label (i18n)
      'home.aircraftsAndFlights', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: '',
        inputs: [{
          dataType: DATA_TYPE_AIRCRAFT_CATEGORIES
        }]
      }, // Tile options
      {
        height: 1,
        width: 2
      }), // Experiences
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundColor: 'white',
            backgroundImage: 'url(' + getUrl('files/project/home/visuel_ANIMATIONS_GP2.jpg') + ')',
            color: 'white'
          },
          className: 'home-btn-icon-and-text-lower-left-bg-black'
        },
        icon: {
          className: 'icon-font',
          webfont: 'c'
        }
      }, // Label (i18n)
      'home.experiences', LIST_PAGE_KEY, {
        contextualTitle: '',
        inputs: [{
          dataType: DATA_TYPE_ANIMATIONS
        }]
      }, // Tile options
      {
        height: 2,
        width: 2
      }), // PMR
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundColor: color1
          }
        },
        icon: {
          className: 'icon-font',
          webfont: 'i'
        }
      }, // Label (i18n)
      'home.pmr', // Page & props
      PMR_PAGE_KEY, null, // Tile options
      {
        height: 1,
        width: 1
      }), // Services
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: 'linear-gradient(#ff0614, #ff5664)'
          }
        },
        icon: {
          style: {},
          className: 'icon-font',
          webfont: 'k'
        }
      }, // Label (i18n)
      'home.practicalInfo', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: '',
        inputs: [{
          dataType: DATA_TYPE_SERVICE_CATEGORIES
        }]
      }, // Tile options
      {
        height: 1,
        width: 1
      })]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};

var getDefault = function getDefault(lang, adConfig) {
  return {
    /* NO HOME PAGE */
  };
};
/**
 * Return home configuration
 * @param  {string} profile
 * @param  {string} lang
 * @param  {object} adConfig
 * @param  {string} orientation
 * @return {object}
 */
// export const get = (profile, lang, adConfig, orientation) => {
//     return getDefault(lang, adConfig);
// };


export var get = function get(profile, lang, adConfig, orientation, isLoggedIn) {
  switch (profile) {
    // PRO
    case 'pro':
      return getPRO(profile, lang, adConfig, orientation, isLoggedIn);
    // GRAND PUBLIC

    case 'gp':
      return getGP(profile, lang, adConfig, orientation, isLoggedIn);
    // PRESSE

    case 'presse':
      return getPRESS(profile, lang, adConfig, orientation, isLoggedIn);

    default:
      if (!profile) {
        console.warn(LOG_PREF + 'No profile set yet');
      } else {
        console.error(LOG_PREF + 'Unsupported profile: ' + profile);
      }

  }
};