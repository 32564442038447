import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { EXHIBITOR_PAGE_KEY } from 'src/pages/pagesKeys';

/**
 * Common component to display related exhibitor
 * @param {array} exhibitor
 * @param {object} labels
 * @param {object} store connected actions
 */
class DetailExhibitor extends PureComponent {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.actions.navigate(EXHIBITOR_PAGE_KEY, { id: this.props.exhibitor.id });
  }

  render() {
    if (!this.props.exhibitor) {
      // Skip component rendering
      return null;
    }

    return (
      <div className="activity detail-exhibitor clickable" onClick={this.onClick}>
        <div className="prop-img">
          <div className="prop-left label">
            <div className="color-grey-dark">
              <span>{this.props.labels.data.exhibitors.singular}</span>
            </div>
          </div>
          <div className="prop-right name-label flex-ellipsis">
            <div className="activities-content ">
              <span className="link colored-link">{this.props.exhibitor.title}</span>
            </div>
          </div>
          <div className="prop-right-fleche">
            <span className="fleche fa fa-chevron-right" />
          </div>
        </div>
      </div>
    );
  }
}

DetailExhibitor.propTypes = {
  exhibitor: PropTypes.object,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default DetailExhibitor;
