import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Measure from 'react-measure';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { throttle } from 'lodash-custom';
import moment from 'moment';

import { FLIGHTS_SCHEDULE_PAGE_KEY } from 'src/pages/pagesKeys';

import * as actions from 'src/store/actions';
import { REFRESH_DATA } from 'src/store/actionTypes';

import { formatDayMonthLong, formatDayMonthVeryLong } from 'src/core/Lang';

import { DATA_TYPE_FLIGHTS_SCHEDULE } from 'data/config/dataConfig';

import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import CTAButton from 'src/components/cta-button/CTAButton';
import Menu from 'src/components/menu/Menu';
import Loader from 'src/components/loader/Loader';
import List from 'src/components/list/List';
import TypeBar from 'src/components/type-bar/TypeBar';
import SideIndex from 'src/components/side-index/SideIndex';
import { isIOS, isFirefox } from 'src/core/util/browser';
import scrollHack from 'src/core/util/scrollHack';

import { getAircraftOriginalId } from './flightsReducer';

import './FlightSchedulePage.scss';

const LOG_PREF = '[FlightSchedulePage] ';

class FlightSchedulePage extends Component {
  static propTypes = {
    currentIndex: PropTypes.number.isRequired,
    ids: PropTypes.object,
    eventsByDays: PropTypes.object,
    isPending: PropTypes.bool,
    itemNotFound: PropTypes.bool,
    profile: PropTypes.string.isRequired,
    // Common page props
    menuOptions: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    isActive: PropTypes.func.isRequired,
    queryString: PropTypes.string.isRequired,
    setDocumentContext: PropTypes.func.isRequired,
    isLocationEnabled: PropTypes.bool,
    isVisible: PropTypes.bool, // set by togglePageAfterNavigation common reducer function
    // toolbar
    hasToolbar: PropTypes.bool,
    homeButtonInToolbar: PropTypes.bool,
    backButtonInToolbar: PropTypes.bool,
    searchButtonInToolbar: PropTypes.bool,
    favButtonInToolbar: PropTypes.bool,
    menuButtonInToolbar: PropTypes.bool,
  };

  wrapperRef = React.createRef();

  UNSAFE_componentWillMount() {
    this.pageKey = FLIGHTS_SCHEDULE_PAGE_KEY;
  }

  /**
   * Update string visible in browser tab/history/favorites
   */
  setDocumentContext() {
    if (this.props.isActive(this.pageKey)) {
      this.props.setDocumentContext(this.getPageTitle());
    }
  }

  /**
   * String displayed in app toolbar
   * @return {string}
   */
  getPageTitle() {
    return this.props.labels.data[DATA_TYPE_FLIGHTS_SCHEDULE].title;
  }

  componentDidMount() {
    this.setDocumentContext();
  }

  componentDidUpdate() {
    this.setDocumentContext();
  }

  setContainerEl = (el) => {
    if (el) {
      this.containerEl = el;
      if (isIOS() || isFirefox()) {
        this.scroller = scrollHack(this.containerEl);
      }
    }
  };

  scrollTo = throttle((offset) => {
    let _offset = offset - (this.headerHeight || 0) - this.wrapperRef.current.offsetTop; // AR-50

    if (this.scroller) {
      this.scroller(_offset);
    } else if (this.containerEl) {
      this.containerEl.scrollTop = _offset;
    }
  }, 80);

  onHeaderDimensionsUpdate = (measure) => {
    this.headerHeight = measure.client.height;
  };

  getHeader() {
    const pageLabels = this.props.labels.data[DATA_TYPE_FLIGHTS_SCHEDULE];

    return (
      <Measure client onResize={this.onHeaderDimensionsUpdate}>
        {({ measureRef }) => (
          <div ref={measureRef} className="flight_schedule_header">
            <CTAButton action={this.props.refresh} label={pageLabels.refresh} />

            {this.props.failedLastAttempt && (
              <div>
                <span className="subtitle-style subtitle-red">{pageLabels.errorWhileFetching}</span>
              </div>
            )}

            <div>
              {this.props.lastFetched ? (
                <span className="subtitle-style">
                  {pageLabels.lastRefreshed +
                    moment(this.props.lastFetched).format(
                      pageLabels.longDateFormat,
                      pageLabels.locale
                    )}
                </span>
              ) : (
                <span></span>
              )}
            </div>
          </div>
        )}
      </Measure>
    );
  }

  getDetail() {
    if (this.props.failedLastAttempt) {
      return null;
    }

    // No result
    else if (this.props.itemNotFound === true) {
      return <div className="no-result">{this.props.labels.data.flights_schedule.noResults}</div>;
    } else if (this.props.isPending === true) {
      return <Loader labels={this.props.labels} />;
    } else {
      let separatorEls = {};

      let original_id;
      if (this.props.id) {
        original_id = getAircraftOriginalId(this.props.id);
      }

      let filter;
      if (original_id) {
        filter = (event) => event.aeronef_id == original_id;
      } else {
        filter = () => true;
      }

      return (
        <div className="flights-schedule-container content-font">
          <div className="flights-schedule-list" ref={this.setContainerEl}>
            {Object.keys(this.props.eventsByDays)
              .sort()
              .map((day) => (
                <div key={day}>
                  <TypeBar
                    isClickable={false}
                    label={formatDayMonthVeryLong(parseInt(day, 10))}
                    ref={(ref) => {
                      separatorEls[day] = ReactDOM.findDOMNode(ref);
                    }}
                  />

                  <List
                    items={this.props.eventsByDays[day].filter(filter)}
                    dataType={DATA_TYPE_FLIGHTS_SCHEDULE}
                    isPending={this.props.isPending}
                    displayFavorites={false}
                    favorites={null}
                    actions={this.props.actions}
                    labels={this.props.labels}
                    pageKey={this.pageKey}
                  />
                </div>
              ))}
          </div>
          <SideIndex
            indexes={Object.keys(this.props.eventsByDays).sort()}
            separatorsGetter={() => separatorEls}
            scrollTo={this.scrollTo}
            getLabel={(day) => formatDayMonthLong(parseInt(day, 10))}
          />
        </div>
      );
    }
  }

  render() {
    console.log(LOG_PREF + 'render');

    if (!this.props.isVisible) {
      return null;
    }

    return (
      <>
        <Menu
          options={this.props.menuOptions}
          actions={this.props.actions}
          labels={this.props.labels}
          profile={this.props.profile}
          associatedPageKey={this.pageKey}
          adConfig={this.props.adConfig}
          twoColumns={this.props.twocolumns}
          isLocationEnabled={this.props.isLocationEnabled}
        />

        <AppToolbar
          labels={this.props.labels}
          isDisplayed={this.props.hasToolbar}
          actions={this.props.actions}
          title={this.getPageTitle()}
          pageKey={this.pageKey}
          profile={this.props.profile}
          hasBackButton={this.props.backButtonInToolbar}
          hasHomeButton={this.props.homeButtonInToolbar}
          hasFavButton={this.props.favButtonInToolbar}
          hasSearchButton={this.props.searchButtonInToolbar}
          hasMenuButton={this.props.menuButtonInToolbar}
        />

        <div ref={this.wrapperRef} className="content-below-apptoolbar">
          {this.getHeader()}
          {this.getDetail()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => state[FLIGHTS_SCHEDULE_PAGE_KEY];

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    refresh: () => dispatch({ type: REFRESH_DATA }), // WTF
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightSchedulePage);
