import { FLIGHTS_SCHEDULE_PAGE_KEY } from 'src/pages/pagesKeys';

import FlightSchedulePage from './FlightSchedulePage';
import { reducer } from './flightsReducer';

export default {
  key: FLIGHTS_SCHEDULE_PAGE_KEY,
  path: '/flights_schedule',
  elId: 'flights-schedule',
  component: FlightSchedulePage,
  getReduxReducer: (state, action) => reducer(state[FLIGHTS_SCHEDULE_PAGE_KEY], action),
};
