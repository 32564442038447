import { DOCUNIT_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import DocunitContent from './DocunitContent';

export default {
  key: DOCUNIT_PAGE_KEY,
  path: '/docunit',
  elId: DOM_ID,
  className: 'docunit-page',
  component: GenericItemPage,
  childComponent: DocunitContent,
  relatedDataToFetch: ['documents', 'parent'],
};
